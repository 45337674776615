import { Employee } from '../domain/employee';
import { Photo } from '../domain/photo';
import AstridPhilipsen from '../resources/img/medewerkers/astrid_philipsen.jpg';
import CoVanBeijsterveldt from '../resources/img/medewerkers/co_van_beijsterveldt.jpg';
import ElsBloemheuvel from '../resources/img/medewerkers/els_bloemheuvel.jpg';
import KirstenBakkenes from '../resources/img/medewerkers/kirsten_bakkenes.jpg';
import MoniqueVanDerHeijden from '../resources/img/medewerkers/monique_van_der_heijden.jpg';
import YvetteVanOers from '../resources/img/medewerkers/yvette_van_oers.jpg';
import WendyVanBeijsterveldt from '../resources/img/medewerkers/wendy_van_beijsterveldt.jpg';

const createEmployee = (name: string,
                        functionTitle: string,
                        description: string[],
                        photoUrl: string,
                        workingHours?: string): Employee => {
  return new Employee(name, functionTitle, description, new Photo(photoUrl, name), workingHours);
};

export const employees = [
  createEmployee('Co van Beijsterveldt', 'Huisarts',
      ['Na mijn huisartsenopleiding was het lastig om aan werk te komen. Samen met Wendy, mijn echtgenote, kregen we al snel het goede plan om in Dorst te beginnen. We waren enthousiast geraakt door mijn tante en ik kende de omgeving omdat ik niet ver hier vandaan geboren ben. Een belangrijke beslissing voor ons verdere leven waar we nooit een moment spijt van hebben gehad.',
        'We voelen ons hier thuis en vinden het heerlijk om onderdeel te zijn van het dorp en voor anderen wat te kunnen betekenen. Ik geniet van de contacten in de praktijk. Het raakt me vooral als mensen iets voor elkaar over hebben.'],
      CoVanBeijsterveldt, 'Sinds Willemijn Mulder bij ons werkt ben ik op donderdag niet meer in de praktijk aanwezig en werk ik op maandag, dinsdag, woensdag en vrijdag.'),
  createEmployee('Astrid Philipsen', 'Praktijkondersteuner', [
      'Mijn naam is Astrid Philipsen, ik ben getrouwd en heb 3 volwassen kinderen. Sinds 5 jaar woon ik in Breda.',
      'Ik heb in het verleden de opleiding voor doktersassistente gedaan, ben daarna gelijk doorgegaan voor de opleiding operatieassistente, en ben hier 12 jaar in  werkzaam geweest.',
      '5 jaar geleden ben ik begonnen met de opleiding voor Praktijkondersteuner Somatiek, dit wil zeggen dat ik patiënten begeleid met chronische ziektes, zoals diabetes type 2, hart- en vaatziekten en astma/copd. Ik ben werkzaam in een huisartsenpraktijk in Roosendaal en sinds 1 juli 2019 ben ik met veel plezier aan het werk in praktijk van Beijsterveldt.'
  ], AstridPhilipsen, 'Mijn werkdagen zijn de woensdagmiddag en de donderdag'),
  createEmployee('Monique van der Heijden', 'Praktijkondersteuner GGZ',
      ['Mijn naam is Monique van der Heijden. Ik ben getrouwd, heb 2 zonen en woon in Oosterhout.',
        'In 1999 ben ik afgestudeerd als HBO-Verpleegkundige met de specialisatie psychiatrie aan de Hogeschool West-Brabant in Breda. Na mijn opleiding heb ik op verschillende afdelingen gewerkt als (senior)verpleegkundige en trainer binnen de gespecialiseerde Geestelijke Gezondheidszorg (GGZ Breburg).',
        'Sinds 1 januari 2019 werk ik als Praktijkondersteuner Huisarts Geestelijke GezondheidsZorg (POH-GGZ) in de huisartsenpraktijk. Als POH-GGZ bied ik zorg op het gebied van psychische en sociale klachten; zoals somberheid, depressie, angst, burn-out, onverklaarde lichamelijke klachten, spanningen, slaapproblemen, rouwverwerking en relatieproblemen. Het bieden van laagdrempelige zorg, het samen op pad gaan met de patiënt om de juiste weg te vinden en hem in zijn eigen kracht te zetten op weg naar zijn eigen herstel vind ik een mooie uitdaging in mijn werk.',
        'Naast dat ik ondersteuning, begeleiding en behandeling biedt in de huisartsenpraktijk werk ik samen met andere hulpverleners in de regio, zoals o.a. psychologen, psychosomatisch fysiotherapeut, verslavingsconsulenten, GGZ-instellingen.']
      , MoniqueVanDerHeijden, 'Mijn werkdagen zijn; dinsdag en donderdagochtend.'),
  createEmployee('Els Bloemheuvel', 'Doktersassistente', ['Een dienstverlenende functie in de medische wereld heeft altijd mijn interesse gehad. Na jaren gewerkt te hebben in een commercieel-administratieve omgeving koos ik ervoor mij te laten omscholen tot doktersassistente. Na mijn stageperiode bij deze praktijk heb ik in 2013 mijn diploma doktersassistente behaald. Sindsdien werk ik hier met veel plezier. Ik ervaar deze praktijk als een fijne werkplek en vind het belangrijk om onze patiënten zo goed mogelijk te helpen en te woord te staan.'], ElsBloemheuvel, 'Ik ben werkzaam op maandag t/m donderdag'),
  createEmployee('Yvette van Oers', 'Doktersassistente',
      ['Hallo, mijn naam is Yvette van Oers.',
        'Ik woon in Breda, in de mooie wijk Waterdonken samen met mijn man en 2 kinderen.',
        'Mijn affiniteit met zorg en zorgen voor is er al heel mijn leven. Ik heb 22 jaar met heel veel plezier gewerkt in verzorgingshuizen, als verzorgende-IG.  In 2018 ben ik mij gaan omscholen tot doktersassistente. Als onderdeel van mijn opleiding ben ik stage komen lopen in Dorst in september 2018 en mocht ik in januari 2019 zelfs komen werken.'],
      YvetteVanOers, 'Mijn werkdagen zijn op maandag, dinsdag, woensdagochtend en vrijdag.'),
  createEmployee('Kirsten van Gageldonk-Bakkenes', 'Doktersassistente', ['Mijn naam is Kirsten van Gageldonk-Bakkenes, ik ben sinds juli 2019 werkzaam in de praktijk. In mijn opleiding tot doktersassistente heb ik hier twee keer stage mogen lopen en daarna ben ik hier aan de slag gegaan. Het werk in de praktijk spreekt mij aan omdat het een dorpspraktijk is en je daardoor de tijd kunt nemen voor de patiënten.'], KirstenBakkenes, 'Ik ben werkzaam op de maandag, woensdag, donderdag en vrijdag'),
  createEmployee('Wendy van Beijsterveldt-Morre', 'Praktijkmanager',
      ['Op 19 september 1985 zijn mijn echtgenoot Co en ik onze huisartsenpraktijk in Dorst gestart. Mijn werkzaamheden waren destijds zeer uiteenlopend, van assistentiewerkzaamheden tot aan de (financiële) administratie.  Met de groei van de praktijk en het gezin werd het na 17 jaar tijd voor uitbreiding met een doktersassistente.',
        'In september 2008 openden we onze vestiging in Bavel en in de afgelopen jaren zijn we dusdanig gegroeid dat ook ons team is uitgebreid met diverse zorgverleners. Door deze ontwikkeling is mijn aandacht nu gericht op het praktijkmanagement en hou ik me vooral bezig met de organisatorische en administratieve taken. Ondanks dat ik nu meer op de achtergrond werkzaam ben is mijn betrokkenheid met de praktijk en de patiënten zeker niet veranderd.',
        'Ik hoop met mijn bijdrage het team te kunnen ondersteunen zodat zij zich kunnen richten op goede zorg voor u.'], WendyVanBeijsterveldt)
];

